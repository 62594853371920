import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactUs = () => (
    <Layout>
        <SEO title="Contact us" />
        <div className="grid-container narrow">
            <section className="typography text-center">
                <h1 class="text-shadow pink">Contact us</h1>
                <p>The Melbourne Institute is a research department located within the Faculty of Business and Economics at the University of Melbourne.</p>

                <h2 className="text-shadow pink">You can reach us via:</h2>
                <p>Email <a href="mailto:hilda-inquiries@unimelb.edu.au" className="modal-link"><span></span><span>hilda-inquiries@unimelb.edu.au</span></a></p>
                <p>Phone <a href="tel:+61 3 8344 2108" className="modal-link"><span></span><span>+61 3 8344 2108</span></a></p>
                
            </section>
        </div>
    </Layout>
)

export default ContactUs
